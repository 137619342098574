.menu{
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;

  .logo{
    font-size: 2rem;
  }

  nav {
    display: flex;
    min-width: 50%;

    ul {
      display: flex;
      justify-content: space-around;
      padding: 0;
      margin: 0;
      width: 100%;

      li {
        display: flex;
        list-style: none;
        align-items: center;
        font-size: 1.2rem;

        &:hover,
        &:active,
        &:hover a,
        &:active a{
          background: $primary;
          color: $white;
        }

        a{
          padding: .2rem 3vw;
        }
      }
    }

  }
}