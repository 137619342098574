.register-form{
  display: flex;
  min-width: 50vw;
  max-width: 700px;
  flex-wrap: wrap;

  form{
    width: 100%;

    .actions{
      display: flex;
      justify-content: space-around;
    }
  }
}