.tasks{
  display: flex;
  flex-wrap: wrap;

  .task{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: .5rem;

    .task-status input{
      cursor: pointer;
      width: 50px;
      height: 1.2rem;
    }

    .name,
    .update-task{
      flex-grow: 1;
      padding: 0 .5rem;
      transition: 200ms;

      input{
        width: 100%;
        font-size: 1.3rem;
      }

      &.done{
        text-decoration: line-through;
        color: $grey;
        transition: 200ms;
      }
    }

    .remove-task{
      cursor: pointer;
      padding-right: 1rem;
    }
  }
}
