.boards{
  display: flex;
  flex-wrap: wrap;

  .board{

    a {
      display: block;
      padding: .5rem;
      color: $primary;
    }

    &:hover,
    &:active{
      background: $primary;

      a {
        color: $white;
      }
    }
  }
}


@media (max-width: 480px) {
  .boards{
    .board{
      width: 100%;
      text-align: center;

      a {
        width: 100%;
      }
    }
  }
}