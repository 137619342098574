@import "./grid";
@import "./input-text";
@import "./buttons";

*{
  box-sizing: border-box;
}

h1{
  text-align: center;
  margin: 0 auto 1rem auto;
  width: 100%;
}