.container{
  display: flex;
  justify-content: center;

  > div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;

    &.public-wrapper{
      flex-direction: row;
    }
  }
}