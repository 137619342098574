.input-group{
  display: flex;
  flex-wrap: wrap;
  padding: .3rem 0 1rem 0;

  label{
    width: 100%;
  }

  input{
    width: 100%;
    padding: .3rem;
    font-size: 1.3rem;
  }
}