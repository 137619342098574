button{
  padding: .3rem 1rem;
  font-size: 1.2rem;
  background: $primary;
  color: $white;
  border: 1px solid $primary;
  transition: 200ms;
  cursor: pointer;

  &:hover,
  &:active{
    background: $white;
    color: $primary;
    border-radius: 5px;
    transition: 200ms;
  }
}